
.blur {
  filter: blur(2px);
}
.styling{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
  /* background-color: #f69601; */
  padding: 2rem;
}
.btn-std-reg{
  background-color: #009300;
  border: none;
    border-radius: 10px;
    padding: 10px;
    color: white;
    letter-spacing: 2px;
    word-spacing: 9px;
    margin-top: 2rem !important;
    
}

* {
  font-family: Poppins;
}
.banner {
  background-color: #3eb7ff;
  background-image: url("/public/Home/banner/BgImg.svg");
}
.logoimg {
  /* background-color: white; */
  /* border-radius: 0px 0px 60px 60px; */
  background-color: white;
    border-radius: 0px 0px 40px 40px;
  width: 450px;
}

.school-link-contain{
  width: 70%;
  font-weight: 600 !important;
  font-size: 17px !important;
}
.redirect-text{
  color: yellow;
  background-color: transparent !important;
}

.school-link{
  cursor: pointer;
  color: blue;
  font-weight: bold;
}


/* Ranking */
.caption {
  width: 50%;
  margin: auto;
  font-size: 1rem;
}
/* Ranking */

/* process */
.process {
  width: 50%;
  margin: auto;
}
.step1 {
  text-align: end;
}
/* process */

/* Award */
.awardBox {
  background: rgb(255, 255, 255, 0.26);
  border-radius: 30px;
  width: 50%;
  margin: auto;
}
.award {
  border-radius: 20px !important;
  background-color: white;
}
.star {
  width: 140px;
  height: 215px;
}
.global {
  width: 228px;
  height: 216px;
}
.diamondaward {
  width: 157px;
  height: 223px;
}
.diamond {
  width: 135px;
  height: 223px;
}

.awardText {
  font-size: 30px;
  font-weight: bold;
}

.imageBottom{
    margin-top: -15rem;
}
.col-contain{
  margin-top: 4rem;
}


/* Award */
@media screen and (max-width: 480px) {

  .school-link-contain{
    width: 95%;
  }
  
  .logoimg {
    /* background-color: white; */
    /* border-radius: 0px 0px 60px 60px; */
    width: 280px;
  }

  /* Ranking */
  .caption {
    width: 80%;
    margin: auto;
    font-size: 10px;
  }
  /* Ranking */

  /* process */
  .process {
    width: 100%;
    /* margin: auto; */
  }
  .step,
  .step1 {
    text-align: center;
  }

  /* process */

  /* Awards */
  .awardBox {
    width: 100%;
  }
  .star {
    width: 63px;
    height: 97px;
  }
  .global {
    width: 103px;
    height: 97px;
  }
  .diamondaward {
    width: 71px;
    height: 101px;
  }
  .diamond {
    width: 71px;
    height: 101px;
  }
  .awardText {
    font-size: 13px;
  }
  .award {
    border-radius: 20px !important;
    background-color: white;
    height: 170px;
  }
  .imageBottom{
    margin-top: -5rem;
}
  /* Awards */
}
